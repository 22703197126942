import React from 'react'
import { Flex, Box, Divider, Text, Heading } from 'theme-ui'
import { Link } from 'gatsby';

const textStyles = {
  marginBottom: 3,  // Adds space between Text items. Adjust value as needed.
  fontSize: 3,
};

export const PostJonathan = props => (
  <Box>
    <Divider />
    <Box>

      <Heading as="h3">不是為了考試而學習，而是讓您的大腦在實際使用英語方面變得更為出色！</Heading>
      <Text {...props} sx={textStyles}><em>沒有時間？</em> 每個人每天都有五分鐘，即使是忙碌的成年人。</Text>
      <Text {...props} sx={textStyles}>這就是我設計 <strong>Parkinson's Writing</strong> 的原因——為了讓忙碌的成年人能夠以最有效的方式提升他們的英語。現在，您可以將這種促進神經可塑性的練習與全英語的教室環境結合，將您的英語水平提升到一個全新的高度。</Text>
      <Text {...props} sx={{ ...textStyles, marginBottom: 0 }}>
        <Link to="/class-parkinsons-zh/">點這裡了解更多</Link>
      </Text>


    </Box>
  </Box>
)
